import "../components/App/App.css";
// import image1 from "../images/image-1.png";

const Home = () => {
  return (
    <div className="title-container">
      {/* <img src={image1} alt="title-logo" /> */}
      <h1 className="name-header">Maria DelSignore</h1>
      <p>I'm a Software Engineer based in CO, USA.</p>  
    </div>
  )
}

export default Home;